var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "gora-logo",
        xmlns: "http://www.w3.org/2000/svg",
        height: "20.8mm",
        width: "129.9mm",
        version: "1.1",
        viewBox: "0 0 460.14289 73.714294"
      }
    },
    [
      _c(
        "g",
        { attrs: { id: "layer", transform: "translate(-194.7 -411.3)" } },
        [
          _c("g", { attrs: { fill: "#777" } }, [
            _c("path", {
              attrs: {
                d:
                  "m249.1 442.2-7.96 21.04h-2.68l-6.64-17.24-6.64 17.24h-2.68l-7.92-21.04h2.72l6.6 17.88 6.76-17.88h2.44l6.72 17.88 6.68-17.88h2.6z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m253.4 442.2h2.84v21.04h-2.84v-21.04zm1.44-4.6q-0.88 0-1.48-0.6t-0.6-1.44q0-0.8 0.6-1.4t1.48-0.6 1.48 0.6q0.6 0.56 0.6 1.36 0 0.88-0.6 1.48t-1.48 0.6z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m275.3 462q-0.8 0.72-2 1.12-1.16 0.36-2.44 0.36-2.96 0-4.56-1.6t-1.6-4.52v-12.72h-3.76v-2.4h3.76v-4.6h2.84v4.6h6.4v2.4h-6.4v12.56q0 1.88 0.92 2.88 0.96 0.96 2.72 0.96 0.88 0 1.68-0.28 0.84-0.28 1.44-0.8l1 2.04z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m291.1 442.1q3.96 0 6.28 2.32 2.36 2.28 2.36 6.68v12.2h-2.84v-11.92q0-3.28-1.64-5t-4.68-1.72q-3.4 0-5.4 2.04-1.96 2-1.96 5.56v11.04h-2.84v-29.68h2.84v12.32q1.16-1.84 3.2-2.84t4.68-1z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m391.7 442.1q2.96 0 5.36 1.36 2.4 1.32 3.76 3.76t1.36 5.56-1.36 5.56-3.76 3.8-5.36 1.36q-2.64 0-4.76-1.12-2.08-1.12-3.36-3.24v4.16h-2.72v-29.68h2.84v12.64q1.32-2.04 3.36-3.08 2.08-1.08 4.64-1.08zm-0.2 18.88q2.2 0 4-1 1.8-1.04 2.8-2.92 1.04-1.88 1.04-4.28t-1.04-4.28q-1-1.88-2.8-2.88-1.8-1.04-4-1.04-2.24 0-4.04 1.04-1.76 1-2.8 2.88-1 1.88-1 4.28t1 4.28q1.04 1.88 2.8 2.92 1.8 1 4.04 1z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m425 442.2-10.52 23.56q-1.28 2.96-2.96 4.2t-4.04 1.24q-1.52 0-2.84-0.48t-2.28-1.44l1.32-2.12q1.6 1.6 3.84 1.6 1.44 0 2.44-0.8 1.04-0.8 1.92-2.72l0.92-2.04-9.4-21h2.96l7.92 17.88 7.92-17.88h2.8z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m445.8 463.7q-4.72 0-6.76-2.88-2-2.92-2-8.32v-10.84q0-5.52 2.12-8.36 2.16-2.88 7.36-2.88 5 0 7.12 2.44t2.12 7.04v1.88h-6.4v-2.28q0-2.24-0.52-3.28-0.48-1.08-2.24-1.08-1.8 0-2.32 1.2t-0.52 3.48v14.4q0 2.28 0.64 3.52 0.64 1.2 2.4 1.2 1.72 0 2.4-1.28 0.72-1.32 0.72-3.64v-3.44h-3.16v-4h9.12v16.64h-4.32l-0.44-2.88q-1.48 3.36-5.32 3.36z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m469.5 463.7q-5.16 0-7.36-2.68-2.2-2.72-2.2-7.96v-12.08q0-5.24 2.2-7.88t7.36-2.64q5.12 0 7.32 2.68 2.24 2.64 2.24 7.84v12.12q0 5.2-2.24 7.92-2.2 2.68-7.32 2.68zm0-4.72q1.68 0 2.24-1.12 0.6-1.16 0.6-3.16v-15.32q0-2-0.6-3.08-0.56-1.12-2.24-1.12t-2.28 1.12q-0.56 1.08-0.56 3.08v15.32q0 2.04 0.56 3.16t2.28 1.12z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m483.4 430.9h8.4q5.24 0 7.88 1.92t2.64 6.64q0 3.16-0.88 5.12-0.88 1.92-3.12 2.92l4.48 15.8h-6.76l-3.68-14.68h-2.4v14.68h-6.56v-32.4zm8.52 13.8q2.32 0 3.24-1.12 0.96-1.16 0.96-3.6 0-2.4-0.84-3.52t-3.04-1.12h-2.28v9.36h1.96z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m511.5 430.9h7.32l6.24 32.4h-6.08l-1.2-7.12h-5.12l-1.24 7.12h-6.2l6.28-32.4zm5.64 21.2-1.96-13.04-1.92 13.04h3.88z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m546.6 463.7q-4.48 0-6.72-2.44-2.24-2.48-2.44-7.64l5.68-0.96q0.08 3.08 0.84 4.56 0.8 1.44 2.36 1.44 1.16 0 1.68-0.72 0.52-0.76 0.52-1.92 0-1.92-0.88-3.24-0.84-1.36-2.68-2.96l-3.36-2.92q-2-1.68-3.08-3.6-1.04-1.92-1.04-4.6 0-3.96 2.32-6.08 2.36-2.16 6.48-2.16 4.4 0 6.12 2.56 1.72 2.52 1.96 6.44l-5.72 0.84q-0.08-2.56-0.6-3.76-0.48-1.24-1.96-1.24-1.08 0-1.68 0.84-0.6 0.8-0.6 1.92 0 1.6 0.68 2.72 0.72 1.08 2.24 2.4l3.28 2.88q2.32 2.04 3.64 4.32 1.32 2.24 1.32 5.28 0 2.32-1.04 4.16-1.04 1.8-2.96 2.84-1.88 1.04-4.36 1.04z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m565.1 463.5q-3.48 0-4.84-1.56-1.32-1.56-1.32-4.76v-13.12h-2.28v-3.96h2.28v-6.96h6.12v6.96h3.36v3.96h-3.36v12.52q0 2.04 2.04 2.04 1.08 0 1.72-0.16v4.72q-2.12 0.32-3.72 0.32z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m575.8 463.7q-2 0-3.08-1.48-1.04-1.48-1.04-3.8v-18.24h6.04v17.4q0 0.96 0.32 1.44 0.36 0.48 1.16 0.48 0.84 0 2.12-0.88v-18.44h6.04v23.12h-6.04v-2.16q-2.76 2.56-5.52 2.56z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m596.5 463.7q-5.44 0-5.44-7.64v-8.48q0-3.56 1.36-5.68t4.08-2.12q2.28 0 4.4 1.84v-10.72h6.04v32.4h-6.04v-1.72q-2.12 2.12-4.4 2.12zm2.48-4q0.88 0 1.92-0.68v-14.72q-1.04-0.6-1.92-0.6-0.96 0-1.4 0.84-0.44 0.8-0.44 2.16v9.8q0 3.2 1.84 3.2z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m611.2 431.8h6.08v4.52h-6.08v-4.52zm0.04 8.32h6.04v23.12h-6.04v-23.12z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "m628.8 463.7q-3.76 0-5.8-2-2-2-2-5.8v-8.32q0-3.8 2-5.8 2.04-2 5.8-2t5.76 2q2.04 2 2.04 5.8v8.32q0 3.8-2.04 5.8-2 2-5.76 2zm0.04-4.08q1.04 0 1.4-0.8 0.4-0.84 0.4-2.48v-9.16q0-1.64-0.4-2.48-0.36-0.84-1.4-0.84-1.08 0-1.48 0.88-0.4 0.84-0.4 2.44v9.16q0 1.6 0.4 2.44t1.48 0.84z"
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }