var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "side-menu" },
    [
      _c("span", {
        staticClass: "menu-close",
        on: { click: _vm.menu.open_toggle }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "menu bg-dark" },
        [
          _c(
            "perfect-scrollbar",
            { staticClass: "scroll-wrap d-flex flex-column" },
            [
              _c(
                "div",
                { staticClass: "menu-header" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "menu-brand",
                      attrs: { as: "div", to: { name: "Arr_List" } }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/logo-sphere.svg"),
                          alt: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_c("b", [_vm._v("AR")]), _vm._v("ROUND")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    !_vm.menu.isMini
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "d-flex mr-2 justify-content-center align-items-center p-0 md-down:d-none",
                            style: {
                              width: "21px",
                              height: "32px"
                            },
                            on: { click: _vm.menu.minimize_toggle }
                          },
                          [
                            _c("i", {
                              class:
                                "" +
                                (_vm.menu.isMini
                                  ? "i-chevron-right"
                                  : "i-chevron-left"),
                              style: { color: "#f0f1f2" }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.menu.isMini
                      ? _c("div", { staticClass: "menu-localization" }, [
                          _vm.language === "en"
                            ? _c("span", { on: { click: _vm.switchLang } }, [
                                _vm._v("RU")
                              ])
                            : _c("span", { on: { click: _vm.switchLang } }, [
                                _vm._v("EN")
                              ])
                        ])
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticClass: "menu-nav",
                  on: { click: _vm.menu.open_toggle }
                },
                [
                  _c(
                    "mega-menu-group",
                    { attrs: { title: _vm.$t("arr_mine"), active: "" } },
                    _vm._l(_vm.arr_list, function(arr, i) {
                      return _c(
                        "mega-menu-group",
                        {
                          key: i,
                          attrs: {
                            to: !arr.blocked
                              ? { name: "Arr", params: { arr_id: arr.idt_arp } }
                              : {
                                  name: "Blocked",
                                  params: { arr_id: arr.idt_arp }
                                },
                            icon: "i-globe",
                            title: arr.name,
                            disabled: arr.blocked,
                            offset_left: !_vm.menu.isMini ? "22.5px" : "0"
                          }
                        },
                        [
                          !arr.blocked
                            ? _c("div", [
                                _c(
                                  "li",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "mega-menu-link",
                                      {
                                        staticClass: "w-100 nav-link",
                                        attrs: {
                                          old_icon: "",
                                          to: {
                                            name: "Rounds",
                                            params: { arr_id: arr.idt_arp }
                                          },
                                          icon: "i-layers"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("rounds")))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "btn mr-2 p-0 btn-dark btn-xs",
                                        attrs: {
                                          to: {
                                            name: "Round_Post",
                                            params: { arr_id: arr.idt_arp }
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "i-plus" })]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "mega-menu-link",
                                      {
                                        staticClass: "w-100 nav-link",
                                        attrs: {
                                          to: {
                                            name: "Models",
                                            params: {
                                              round_type: "3d",
                                              arr_id: arr.idt_arp,
                                              is_active: "active"
                                            }
                                          },
                                          icon: "i-package"
                                        }
                                      },
                                      [
                                        _c("IconBase", [_c("Side3D")], 1),
                                        _vm._v(_vm._s(_vm.$t("models")))
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "btn mr-2 p-0 btn-dark btn-xs",
                                        on: {
                                          click: function($event) {
                                            return _vm.addModel(arr.idt_arp)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "i-plus" })]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "mega-menu-link",
                                      {
                                        staticClass: "w-100 nav-link",
                                        attrs: {
                                          to: {
                                            name: "Models",
                                            params: {
                                              round_type: "gif",
                                              arr_id: arr.idt_arp,
                                              is_active: "active"
                                            }
                                          },
                                          icon: "i-package"
                                        }
                                      },
                                      [
                                        _c("IconBase", [_c("SideGif")], 1),
                                        _vm._v(_vm._s(_vm.$t("gifs")))
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "btn mr-2 p-0 btn-dark btn-xs",
                                        on: {
                                          click: function($event) {
                                            return _vm.addGif(arr.idt_arp)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "i-plus" })]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "mega-menu-link",
                                      {
                                        staticClass: "w-100 nav-link",
                                        attrs: {
                                          to: {
                                            name: "Models",
                                            params: {
                                              round_type: "video",
                                              arr_id: arr.idt_arp,
                                              is_active: "active"
                                            }
                                          },
                                          icon: "i-video"
                                        }
                                      },
                                      [
                                        _c("IconBase", [_c("SideVideo")], 1),
                                        _vm._v(_vm._s(_vm.$t("videos")))
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "btn mr-2 p-0 btn-dark btn-xs",
                                        on: {
                                          click: function($event) {
                                            return _vm.addVideo(arr.idt_arp)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "i-plus" })]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "mega-menu-link",
                                      {
                                        staticClass: "w-100 nav-link",
                                        attrs: {
                                          to: {
                                            name: "Models",
                                            params: {
                                              round_type: "image",
                                              arr_id: arr.idt_arp,
                                              is_active: "active"
                                            }
                                          },
                                          icon: "i-image"
                                        }
                                      },
                                      [
                                        _c("IconBase", [_c("SideImage")], 1),
                                        _vm._v(_vm._s(_vm.$t("images")))
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "btn mr-2 p-0 btn-dark btn-xs",
                                        on: {
                                          click: function($event) {
                                            return _vm.addImage(arr.idt_arp)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "i-plus" })]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    _c(
                                      "mega-menu-link",
                                      {
                                        staticClass: "w-100 nav-link",
                                        attrs: {
                                          to: {
                                            name: "Models",
                                            params: {
                                              round_type: "mask",
                                              arr_id: arr.idt_arp,
                                              is_active: "active"
                                            }
                                          },
                                          icon: "i-camera"
                                        }
                                      },
                                      [
                                        _c("IconBase", [_c("SideMask")], 1),
                                        _vm._v(_vm._s(_vm.$t("masks")))
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "btn mr-2 p-0 btn-dark btn-xs",
                                        on: {
                                          click: function($event) {
                                            return _vm.addMask(arr.idt_arp)
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "i-plus" })]
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "menu-nav mt-auto mb-2" }, [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href:
                          "https://itunes.apple.com/ru/app/arround/id1435766899",
                        target: "_blank"
                      }
                    },
                    [
                      _c("i", { staticClass: "i-apple float-left" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Apple Store")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        href:
                          "https://play.google.com/store/apps/details?id=com.arround.io",
                        target: "_blank"
                      }
                    },
                    [
                      _c("i", { staticClass: "i-android float-left" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Google Play")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: { href: "http://arround.world/", target: "_blank" }
                    },
                    [
                      _c("i", { staticClass: "i-globe float-left" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("ARROUND.world")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "d-flex align-items-center" },
                  [
                    _c(
                      "mega-menu-link",
                      {
                        staticClass: "w-100",
                        attrs: { to: { name: "Profile" }, icon: "i-profile" }
                      },
                      [_vm._v(_vm._s(_vm.$t("profile")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "span",
                    { staticClass: "nav-link", on: { click: _vm.logOut } },
                    [
                      _c("i", { staticClass: "i-power float-left" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("logout")))])
                    ]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("new-model", { ref: "new-model" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }