<template>
	<div class="navbar navbar-page py-3 sticky-top bg-white navbar-shadow">
		<div class="container-fluid">

			<span class="btn bg-light p-0 md-down:d-none" v-if="menu.isMini" @click="menu.minimize_toggle">
				<i :class="menu.isMini ? 'i-chevron-right' : 'i-chevron-left'"></i>
			</span>

			<span v-if="page" class="btn events-disable"> {{page}} </span>

			<span class="btn bg-light p-0 ml-auto lg-up:d-none" @click="menu.open_toggle">
				<i class="i-menu-simple"></i>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PageNavbar',
		computed: {
			menu() {
				if(process.browser)
					return this.$mega.menu;

				return {
					isMini: false,
					open_toggle: () => {},
					minimize_toggle: () => {},
				}
			},
			page() {
				return this.$navbar.name;
			}
		}
	}
</script>
