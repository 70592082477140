var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d:
        "M29.347 34.135H23.209L23.097 10.494H31.566C37.474 10.494 40.812 15.143 40.812 22.513C40.812 29.882 37.725 34.135 29.347 34.135ZM30.895 13.204L26.737 13.507L25.902 31.403H29.334C34.842 31.403 38.076 29.257 38.076 22.416C38.075 15.572 35.584 13.204 30.895 13.204ZM18.774 26.536C18.774 30.993 15.47 34.506 10.096 34.506C7.02499 34.506 5.28899 33.864 4.18799 33.392L5.17799 31.507C7.15899 32.059 15.971 33.222 15.971 26.787C15.971 21.839 9.32999 22.503 7.17799 22.657L6.55799 20.997L14.662 12.832L5.17899 13.507L5.02399 10.494H18.062L18.508 13.314C18.508 13.314 10.898 19.95 10.922 19.942C13.401 19.943 18.774 20.887 18.774 26.536Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }