<template>
	<div class="side-menu">

		<span class="menu-close" @click="menu.open_toggle"></span>

		<div class="menu bg-dark">
			<perfect-scrollbar class="scroll-wrap d-flex flex-column">

				<div class="menu-header">
					<router-link class="menu-brand" as="div" :to="{ name: 'Arr_List' }">
						<img :src="require('../../assets/logo-sphere.svg')" alt="">
						<span><b>AR</b>ROUND</span>
					</router-link>
					<div class="d-flex justify-content-between">
						<span 
							v-if="!menu.isMini"
							class="d-flex mr-2 justify-content-center align-items-center p-0 md-down:d-none" 
							:style="{
								width: '21px',
								height: '32px'
							}"
							@click="menu.minimize_toggle">
							<i 
								:class="`${ menu.isMini ? 'i-chevron-right' : 'i-chevron-left' }`"
								:style="{ color: '#f0f1f2' }"></i>
						</span>
						<div class="menu-localization" v-if="!menu.isMini">
							<span @click="switchLang" v-if="language === 'en'">RU</span>
							<span @click="switchLang" v-else>EN</span>
						</div>
					</div>
				</div>

				<ul class="menu-nav" @click="menu.open_toggle">
					<!-- <mega-menu-link 
						:style="{ marginTop: '4px' }" 
						:to="{name: 'Arr_List'}" 
						icon="i-home" 
						exact>
						{{ $t('arr_mine') }}
					</mega-menu-link> -->

					<mega-menu-group :title="$t('arr_mine')" active>
						<mega-menu-group 
								v-for="(arr, i) in arr_list" 
								:key="i" 
								:to="(!arr.blocked) ? {name: 'Arr', params: {arr_id: arr.idt_arp}} : {name: 'Blocked', params: {arr_id: arr.idt_arp}}" 
								icon="i-globe" 
								:title="arr.name"
								:disabled="arr.blocked"
								:offset_left="!menu.isMini ? '22.5px' : '0'">

							<div v-if="!arr.blocked">
								<!-- <hr :style="{ 
										margin: '4px auto', 
										border: '0', 
										height: '1px',
										backgroundImage: 
											`linear-gradient(
												to right,
												rgba(0,0,0,0),
												rgba(132,193,199,0.3),
												rgba(0,0,0,0)
											)`,
										width: '75%',
									}"> -->

								<li class="d-flex align-items-center">
									<mega-menu-link class="w-100 nav-link" old_icon :to="{name: 'Rounds', params: {arr_id: arr.idt_arp}}" icon="i-layers">{{ $t('rounds') }}</mega-menu-link>
									<router-link :to="{name: 'Round_Post', params: {arr_id: arr.idt_arp}}" class="btn mr-2 p-0 btn-dark btn-xs"><i class="i-plus"></i></router-link>
								</li>

								<li class="d-flex align-items-center">
									<mega-menu-link class="w-100 nav-link" :to="{name: 'Models', params: {round_type: '3d', arr_id: arr.idt_arp, is_active: 'active'}}" icon="i-package"><IconBase><Side3D /></IconBase>{{ $t('models') }}</mega-menu-link>
									<span @click="addModel(arr.idt_arp)" class="btn mr-2 p-0 btn-dark btn-xs"><i class="i-plus"></i></span>
								</li>

								<li class="d-flex align-items-center">
									<mega-menu-link class="w-100 nav-link" :to="{name: 'Models', params: {round_type: 'gif', arr_id: arr.idt_arp, is_active: 'active'}}" icon="i-package"><IconBase><SideGif /></IconBase>{{ $t('gifs') }}</mega-menu-link>
									<span @click="addGif(arr.idt_arp)" class="btn mr-2 p-0 btn-dark btn-xs"><i class="i-plus"></i></span>
								</li>

								<li class="d-flex align-items-center">
									<mega-menu-link class="w-100 nav-link" :to="{name: 'Models', params: {round_type: 'video', arr_id: arr.idt_arp, is_active: 'active'}}" icon="i-video"><IconBase><SideVideo /></IconBase>{{ $t('videos') }}</mega-menu-link>
									<span @click="addVideo(arr.idt_arp)" class="btn mr-2 p-0 btn-dark btn-xs"><i class="i-plus"></i></span>
								</li>

								<li class="d-flex align-items-center">
									<mega-menu-link class="w-100 nav-link" :to="{name: 'Models', params: {round_type: 'image', arr_id: arr.idt_arp, is_active: 'active'}}" icon="i-image"><IconBase><SideImage /></IconBase>{{ $t('images') }}</mega-menu-link>
									<span @click="addImage(arr.idt_arp)" class="btn mr-2 p-0 btn-dark btn-xs"><i class="i-plus"></i></span>
								</li>

								<li class="d-flex align-items-center">
									<mega-menu-link class="w-100 nav-link" :to="{name: 'Models', params: {round_type: 'mask', arr_id: arr.idt_arp, is_active: 'active'}}" icon="i-camera"><IconBase><SideMask /></IconBase>{{ $t('masks') }}</mega-menu-link>
									<span @click="addMask(arr.idt_arp)" class="btn mr-2 p-0 btn-dark btn-xs"><i class="i-plus"></i></span>
								</li>
							</div>
						</mega-menu-group>
					</mega-menu-group>

				</ul>

				<ul class="menu-nav mt-auto mb-2">

					<li class="nav-item">
						<a href="https://itunes.apple.com/ru/app/arround/id1435766899" class="nav-link" target="_blank">
							<i class="i-apple float-left"></i>
							<span>Apple Store</span>
						</a>
					</li>
					<li class="nav-item">
						<a href="https://play.google.com/store/apps/details?id=com.arround.io" class="nav-link" target="_blank">
							<i class="i-android float-left"></i>
							<span>Google Play</span>
						</a>
					</li>
					<li class="nav-item">
						<a href="http://arround.world/" class="nav-link" target="_blank">
							<i class="i-globe float-left"></i>
							<span>ARROUND.world</span>
						</a>
					</li>

					<li class="d-flex align-items-center">
						<mega-menu-link class="w-100" :to="{name: 'Profile'}" icon="i-profile">{{ $t('profile') }}</mega-menu-link>
					</li>

					<li class="nav-item">
					<span class="nav-link" @click="logOut">
						<i class="i-power float-left"></i>
						<span>{{ $t('logout') }}</span>
					</span>
					</li>
				</ul>
			</perfect-scrollbar>
		</div>

		<new-model ref="new-model"/>
	</div>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.menu-collapse {
  position: absolute;
  height: 100%;
  top: 0;
  right: -5px;
  width: 5px;
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }
  &-enter, &-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  &-btn {
    position: absolute;
    background-color: white;
    box-shadow: inset 0px 0px 0px 2px var(--base-color);
    border-radius: 50px;
    width: 30px;
    height: 30px;
    transform: translateY(calc(35px - 50%)) translateX(calc(-50% + 5px / 2));
    cursor: pointer;
  }
  &-border {
    background-color: var(--base-color);
    height: 100%;
    width: 5px;
    cursor: col-resize;
  }
}
</style>

<script>
	import NewModel from './new_model'
	import IconBase from '../../components/icons/IconBase.vue';
	import Side3D from '../../components/icons/set/Side3D.vue';
	import SideGif from '../../components/icons/set/SideGif.vue';
	import SideVideo from '../../components/icons/set/SideVideo.vue';
	import SideImage from '../../components/icons/set/SideImage.vue';
	import SideMask from '../../components/icons/set/SideMask.vue';

	export default {
		name: 'MainSidebar',
		data() {
			return {
				arr_list: [],
				loaded: false
			}
		},
		computed: {
			page() {
				return this.$store.state.page;
			},
			user() {
				return this.$store.state.user;
			},
			session() {
				return this.$store.state.token;
			},
			language() {
				return this.$store.state.lang;
			},
			menu() {
				if(process.browser)
					return this.$mega.menu;

				return {
					open_toggle: () => {},
				}
			}
		},
		mounted() {
			this.getArrList();
		},
		methods: {

			addImage(arr) { this.$refs['new-model'].open({arp: arr, type: 2}) },
			addVideo(arr) { this.$refs['new-model'].open({arp: arr, type: 3}) },
			addModel(arr) { this.$refs['new-model'].open({arp: arr, type: 4}) },
			addGif(arr) { this.$refs['new-model'].open({arp: arr, type: 5}) },
			addMask(arr) { this.$refs['new-model'].open({arp: arr, type: 10}) },

			logOut() {
				return this.$store.dispatch('logout', {idt_session: this.session}).then(() => { window.location.reload() });
			},

			getArrList() {
				this.loaded = false;

				this.$api.v2.get('/arp/my', {params: {limit: 6}})
						.then(response => {
							this.loaded = true;

							this.arr_list = response.data['arps'];
							this.$store.dispatch('arps', response.data['arps']);
						}).catch(() => { this.loaded = true; })
			},
			switchLang() {
				this.$i18n.locale = this.$i18n.locale === 'en' ? 'ru' : 'en';
				this.$store.dispatch('language', this.$i18n.locale);
				this.$router.go(this.$router.currentRoute);
			}
		},
		components: {
			NewModel,
			IconBase,
			Side3D,
			SideGif,
			SideVideo,
			SideImage,
			SideMask
		}
	}
</script>

<!-- moorlex@yandex.ru  bdaab7af -->
