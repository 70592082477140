<template>
    <div class="admin-page bg-lighter" v-if="token" :class="{'side-menu-mini': menu.isMini,'side-menu-open': menu.isOpen}">

        <page-sidebar/>

        <div class="admin-body d-flex flex-column" style="min-height: 100vh">
            <page-navbar/>

            <div style="flex: 1">
                <no-ssr>
                    <router-view/>
                </no-ssr>
            </div>

            <page-footer/>
        </div>
    </div>
</template>

<script>
  import PageFooter from '../components/footer';
  import PageSidebar from '../components/side-menu';
  import PageNavbar from '../components/navbar'

  export default {
    name: 'App',
    data(){
      return {
        sidebar: false
      }
    },
    computed: {
      token() {
        return this.$store.state.token;
      },
      menu() {
        if(process.browser)
          return this.$mega.menu;

        return {
          isMini: false,
          isOpen: false
        }
      }
    },
    components: {
      PageNavbar,
      PageFooter,
      PageSidebar
    }
  }
</script>
