var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "navbar navbar-page py-3 sticky-top bg-white navbar-shadow"
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _vm.menu.isMini
          ? _c(
              "span",
              {
                staticClass: "btn bg-light p-0 md-down:d-none",
                on: { click: _vm.menu.minimize_toggle }
              },
              [
                _c("i", {
                  class: _vm.menu.isMini ? "i-chevron-right" : "i-chevron-left"
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.page
          ? _c("span", { staticClass: "btn events-disable" }, [
              _vm._v(" " + _vm._s(_vm.page) + " ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "btn bg-light p-0 ml-auto lg-up:d-none",
            on: { click: _vm.menu.open_toggle }
          },
          [_c("i", { staticClass: "i-menu-simple" })]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }