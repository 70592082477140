var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "small bg-white" }, [
    _c(
      "div",
      { staticClass: "container-fluid d-flex justify-content-between py-3" },
      [
        _c(
          "div",
          { staticClass: "text-muted" },
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _vm._v("©ARROUND admin")
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$root.year))])
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }