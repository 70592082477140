var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d:
        "M37.5 7.5L38.5 37.5H6.5L7.5 7.5H37.5ZM34.5 10.5L10.5 9.5L8.917 30.083L14.5 24.5L20.5 28.5L28.5 19.5L35.875 27.695L34.5 10.5ZM36.5 35.5L36.236 32.203L28.5 23.5L20.5 31.5L14.5 27.5L8.597 34.246L8.5 35.5H36.5ZM18.5 19.5C16.844 19.5 15.5 18.156 15.5 16.5C15.5 14.844 16.844 13.5 18.5 13.5C20.156 13.5 21.5 14.844 21.5 16.5C21.5 18.156 20.156 19.5 18.5 19.5Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }