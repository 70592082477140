var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.token
    ? _c(
        "div",
        {
          staticClass: "admin-page bg-lighter",
          class: {
            "side-menu-mini": _vm.menu.isMini,
            "side-menu-open": _vm.menu.isOpen
          }
        },
        [
          _c("page-sidebar"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "admin-body d-flex flex-column",
              staticStyle: { "min-height": "100vh" }
            },
            [
              _c("page-navbar"),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { flex: "1" } },
                [_c("no-ssr", [_c("router-view")], 1)],
                1
              ),
              _vm._v(" "),
              _c("page-footer")
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }